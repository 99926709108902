import * as serviceWorker from "./serviceWorker";

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/remote-config";
import "firebase/messaging";
import "firebase/performance";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import React, { Suspense, lazy } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { BatchHttpLink } from "@apollo/client/link/batch-http";

const link = new BatchHttpLink({ uri: "/graphql" });
const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

const App = lazy(() => import("./App"));
const rootElement = document.getElementById("root");
const main = () =>
  render(
    <ApolloProvider client={client}>
      <Suspense fallback={<div />}>
        <Router>
          <App />
        </Router>
      </Suspense>
    </ApolloProvider>,
    rootElement
  );

const firebaseConfig = {
  apiKey: "AIzaSyCtqi4llnfkeEpw-FT6OMhRobhPSl1k8Q4",
  authDomain: "jt-data-1.firebaseapp.com",
  databaseURL: "https://jt-data-1.firebaseio.com",
  projectId: "jt-data-1",
  storageBucket: "jt-data-1.appspot.com",
  messagingSenderId: "127519633540",
  appId: "1:127519633540:web:707c5d6bd4a36267cf92d1",
  measurementId: "G-C28Z6V8RFW"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const browserSupportsAllFeatures = () => {
  return window.requestIdleCallback && window.IntersectionObserver;
};

const loadScript = (src, done) => {
  const js = document.createElement("script");
  js.src = src;
  js.onload = function () {
    done();
  };
  js.onerror = function () {
    done(new Error("Failed to load script " + src));
  };
  document.head.appendChild(js);
};

if (browserSupportsAllFeatures()) {
  main();
} else {
  loadScript(
    "https://polyfill.io/v3/polyfill.min.js?version=3.52.1&features=requestIdleCallback%2CIntersectionObserver",
    main
  );
}

serviceWorker.register();
